import React from 'react';

import Layout from '../layout';
import SEO from '../components/seo';

import Container from '../components/container';
import Grid from '../components/grid';

import Scene from '../images/scene-thank-you-01.inline.svg';

const ThankYouPage = () => {
  return (
    <Layout>
      <SEO title="Thank You!" description={``} />

      <Container>
        <Grid className="my-6 md:my-12">
          <div className="flex flex-col justify-center col-span-12 lg:col-span-5">
            <h1 className="text-4xl font-black leading-tight tracking-tighter xl:text-5xl">
              Thank You!
            </h1>

            <div className="mt-4 text-xl text-gray-700">
              <p className="">We look forward to working with you!</p>
              <p className="mt-4">
                We will contact you very shortly to get the process started. For now, take this time
                to gather any content you would like on your new website!
              </p>
            </div>
          </div>

          <div className="col-span-12 mt-8 lg:mt-0 lg:col-span-7">
            <Scene className="w-full h-auto" />
          </div>
        </Grid>
      </Container>
    </Layout>
  );
};

export default ThankYouPage;
